import { Navigate } from "react-router-dom";

import { Panel, PanelHeader } from "../components/Surfaces";
import { useAuth } from "../context/useAuth";
import { redirectUrls } from "../models/settings";


/** HomePage creates a panel with a welcome message and a link to the login page */
const HomePage = () => {
    const { user } = useAuth();

    if (user && user.bearerToken) {
        return <Navigate to={redirectUrls.dataUpload} replace/>;
    }

    return (
        <Panel centered="true" width="50vw">
            <PanelHeader>Welcome HiLo</PanelHeader>
            <p>Fostering high-tech solutions in low-tech environments</p>
            <p><a href={"#" + redirectUrls.userLogin}>Login</a></p>
            <p>Download the mobile app: <a href={"app-release.apk"}>CocoaCount</a></p>
            <p><img src="app.png" alt="Download the mobile app" /></p>
        </Panel>
    );
};

export default HomePage;