/** Default values and configuration settings that are used within the app */

export const baseUrl = "/api";
//  "https://farmds-test.containers.wur.nl/api";
export const redirectUrls = {
    home: "/",
    overview: "/data/overview",
    userRegistration: "/user/register",
    userLogin: "/user/login",
    dataUpload: "/data/load"
};

