import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { theme } from '../Theme';


export default function ConfirmationDialog(props) {
    return (
        <ThemeProvider theme={theme}>
        <Dialog
            open={props.open}
            onClose={() => props.handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.question}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleClose(true)}>OK</Button>
            </DialogActions>
        </Dialog>
        </ThemeProvider>
    );
}
