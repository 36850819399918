import { Grid, ImageList, ImageListItem } from '@mui/material';

import useStore from '../store/useStore';

/** OverviewPage shows a selection box to select a year and an overview of the farm data and fields for that year */
function OverviewPage() {
    const { store } = useStore();
    let path = store.image;
    
    if(path) {
        path = path.substr(path.indexOf('out/'));

        return (
            <Grid container rowSpacing={2}>
                <Grid item xs={12} md={11}>
                    <p><b>{store.result}</b></p>

                    <ImageList sx={{ width: 2000, height: 2000 }} cols={3} rowHeight={164}>
                        <ImageListItem key="logoWUR.png">
                            <img
                                src={`${path}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`${path}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={path}
                                loading="lazy"
                            />
                        </ImageListItem>
                    </ImageList>
                </Grid>
            </Grid>
        );
    }
}

export default OverviewPage;