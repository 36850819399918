import { useState } from 'react';
import { Box, Tabs as MUITabs, Tab, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { theme } from '../Theme';


/** Default stylized tab panel */
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}            
            aria-labelledby={`tab-${index}`}
            style={{width: "100%"}}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography component={"span"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

/** Default stylized tabs
 * 
 * @param {object} props
 * @param {string} [props.header]
 * @param {string} [props.orientation]
 * @param {string} [props.labels]
 * @param {string} [props.children]
 * @returns
*/
export default function Tabs(props) {
    const [value, setValue] = useState(0);

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };
    
    return (
        <ThemeProvider theme={theme}>
            <Box sx={props.orientation === "vertical" ? { flexGrow: 1, bgcolor: 'background.paper', display: 'flex', minHeight: "82vh" } : {}}>
                <Box sx={props.orientation === "vertical" ? { mr: 2, p: 0, width: props.width ? props.width : '200px' } : {}}>
                    { props.header }
                    { props.header ? <br/> : ""}
                    <MUITabs 
                        value={value} 
                        onChange={handleChange} 
                        orientation={props.orientation ? props.orientation : "horizontal"}                        
                    >
                        {   
                            props.labels.map(
                                label => 
                                <Tab key={label} label={label} sx={props.orientation === "vertical" ? { alignItems: "flex-start", p: 1, textAlign: 'left' } : {}}/>
                            )
                        }
                    </MUITabs>
                </Box>
                {
                    props.children.map(
                        (child, index) => 
                        <TabPanel 
                            key={index} 
                            value={value} 
                            index={index} 
                            style={{width: props.orientation === "vertical" ? (props.width ? 'calc(100% - ' + props.width + ')' : 'calc(100% - 200px)' ) : '100%'}}
                        >
                            {child}
                        </TabPanel>
                    )
                }
            </Box>
        </ThemeProvider>
    );
}
