import { useEffect, useState, useCallback } from "react";
import Alert from "@mui/material/Alert";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import { useAuth } from "../../context/useAuth";
import { checkRegistration } from "../../models/auth";
import Panel, { PanelHeader } from "../Surfaces/Panel";
import { Button } from "../Button";
import { TextField, FieldGroup } from "../Input";
import { checkPassword } from "../../models/util";
import { redirectUrls } from "../../models/settings";


/** RegistrationForm constructs a form to register for the website and redirects to the login page after registration */
const RegistrationForm = () => {
    const [token, setToken] = useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [formState, setFormState] = useState({ firstname: "", lastname: "", email: "", password: "", matchingPassword: "", verified: false });
    const [busy, setBusy] = useState(false);
    const [registered, setRegistered] = useState(false);
    const [alertState, setAlertState] = useState({ message: null, severity: "error" });
    const auth = useAuth();
    const showError = (msg) => setAlertState({ message: msg, severity: "error" });

    const onVerify = useCallback((token) => {
        setToken(token);
    }, [setToken]);

    const getChangeHandler = (parameter) => (event) => {
        const value = event.target.value;
        setFormState((prev) => ({ ...prev, [parameter]: value }));
    };

    const handleRegistration = () => {
        setAlertState({ message: null });

        let error = checkPassword(formState.password);
    
        if (!formState.firstname) {
            showError("Enter your first name");
        } else if (!formState.lastname) {
            showError("Enter your last name");
        } else if (!formState.email) {
            showError("Enter a valid email address");
        } else if (!formState.password) {
            showError("Enter your password");
        } else if (error) {
            showError(error);
        } else if (!formState.matchingPassword) {
            showError("Enter your password again");
        } else if (formState.password !== formState.matchingPassword) {
            showError("Passwords do not match");
        } else {
            setBusy(true);
            auth
            .register(formState.firstname, formState.lastname, formState.password, formState.matchingPassword, formState.email, token)
            .then((data) => {
                setRegistered(true);
            })
            .catch((err) => {
                setRegistered(false);
                if (err.message.includes("already in use")) {
                    showError("The email address is already in use");
                } else {
                    showError("Cannot register the new user");
                }

                console.error(err);
            })
            .finally(() => {
                setBusy(false);
                setRefreshReCaptcha(refreshed => !refreshed);
            });
        }
    };

    if (registered) {
        return (
            <Panel centered="true" width="800px">
                <PanelHeader>Registration confirmation</PanelHeader>
                <div style={{ marginTop: 16 }}>
                    <p>You have registered for HiLo!</p>
                    <p>To complete your registration, you need to confirm your email address. 
                        An email has been sent to the email address you provided with a confirmation link. <b>This confirmation link is valid for 24 hours.</b></p>
                    <p>After confirmation you can log in to HiLo.</p>
                </div>
            </Panel>
        );
    }

    return (
        <Panel centered="true" width="800px">
            <PanelHeader>Registration</PanelHeader>
            <FieldGroup flexDirection="column">
                <TextField
                    label="First name"
                    value={formState.firstname}
                    onChange={getChangeHandler("firstname")}
                    color="primary"
                />
                <TextField
                    label="Last name"
                    value={formState.lastname}
                    onChange={getChangeHandler("lastname")}
                    color="primary"
                />
                <TextField
                    label="Email"
                    value={formState.email}
                    onChange={getChangeHandler("email")}
                    type="email"
                    color="primary"
                />
                <TextField
                    label="Password"
                    value={formState.password}
                    onChange={getChangeHandler("password")}
                    type="password"
                    color="primary"
                />
                <TextField
                    label="Confirm your password"
                    value={formState.matchingPassword}
                    onChange={getChangeHandler("matchingPassword")}
                    type="password"
                    color="primary"
                />
            </FieldGroup>

            {alertState?.message && (<Alert severity={alertState.severity}>{alertState.message}</Alert>)}

            <div style={{ marginTop: 16 }}>
                <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha}/>
                <Button color="primary" disabled={busy} onClick={handleRegistration}>
                    Register
                </Button>
                <a style={{ marginLeft: 16 }} href={'#' + redirectUrls.userLogin}>Log in</a>
            </div>
        </Panel>
    );
};

/** ConfirmRegistrationForm constructs a page to confirm the registration of a user */
export const RegistrationConfirmation = (props) => {
    const [confirmed, setConfirmed] = useState(false);
    const [alertState, setAlertState] = useState({ message: null, severity: "error" });
    const showError = (msg) => setAlertState({ message: msg, severity: "error" });
    
    useEffect(() => {
        checkRegistration(props.token).then((data) => {
            if (data.value) {
                showError(null);
                setConfirmed(true);
            } else {
                showError("Your registration cannot be confirmed. Please try again later.");
                setConfirmed(false);
            }
        }).catch((err) => {
            if (err.message.includes("expired")) {
                showError('Your registration cannot be confirmed. The link used has expired.');
            } else {
                showError('Your registration cannot be confirmed. The link used is not valid.');
            }
        });
    }, [ props.token ]);

    return (
        <Panel centered="true" width="800px">
            <PanelHeader>Confirmation registration</PanelHeader>

            {alertState?.message && (<Alert severity={alertState.severity}>{alertState.message}</Alert>)}

            { confirmed ?
            <div style={{ marginTop: 16 }}>
                <p>You have confirmed your registration for HiLo and activated your account.</p>
                <p><a href={'#' + redirectUrls.userLogin}>Log in</a></p>
            </div>
            : ""}
        </Panel>
    );
};

export default RegistrationForm;