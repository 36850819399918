import {
    createContext,
    useContext,
    useEffect,
    useCallback,
    useState
} from "react";
import {
    basicAuthToken as generateBasicAuthToken,
    login as loginService,
    logout as logoutService,
    getUser,
    register as registerService
} from "../models/auth";


/**
* @typedef {Object} TUser
* @property {string} email
* @property {string} basicAuthToken
* @property {string} bearerToken
* @property {number} id
*
* @typedef TAuthContext
* @property {TUser} user
* @property {function} login
* @property {function} logout
* @property {function} register
*/
  
/** @type {import('react').Context<TAuthContext>} */
let AuthContext = createContext();
  
export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    
    useEffect(() => {
        const bearerToken = sessionStorage.getItem("bearerToken");

        if (bearerToken) {
            getUser(bearerToken)
                .then((userData) => {
                setUser({ ...userData, bearerToken });
            })
            .catch((err) => {
                console.warn("login error:", err);
                setUser(null);
                sessionStorage.setItem("bearerToken", null);
            });
        }
    }, []);
  
    const login = useCallback((email, password, reCaptchaToken) => {
        return new Promise((resolve, reject) => {
            const basicAuthToken = generateBasicAuthToken(email, password);

            loginService(basicAuthToken, reCaptchaToken)
            .then((loginResult) => {
                const newUser = {
                    ...loginResult,
                    basicAuthToken,
                };
                setUser(newUser);
                sessionStorage.setItem("bearerToken", loginResult.bearerToken);
                resolve(newUser);
            })
            .catch((loginError) => {
                console.warn("loginError:", loginError);
                setUser(null);
                reject(loginError);
            });
        });
    }, []);
  
    const logout = useCallback(() => {
        return new Promise((resolve, reject) => {
            const bearerToken = sessionStorage.getItem("bearerToken");

            if (!bearerToken) {
                setUser(null);
                reject(new Error("token not found"));
            }

            logoutService(bearerToken)
            .then(() => {
                setUser(null);
                resolve();
            })
            .catch(reject)
            .finally(() => {
                setUser(null);
                sessionStorage.setItem("bearerToken", null);
            });
        });
    }, []);
  
    const reloadUser = useCallback(() => {
        const bearerToken = sessionStorage.getItem("bearerToken");

        if (bearerToken) {
          return getUser(bearerToken)
            .then((userData) => {
              setUser({ ...userData, bearerToken });
            })
            .catch((err) => {
              console.warn("login error:", err);
              setUser(null);
              sessionStorage.setItem("bearerToken", null);
            });
        }
    }, []);
    
    const register = useCallback((email, password, matchingpassword, name, company, reCaptchaToken) => {
        return new Promise((resolve, reject) => {
            registerService(email, password, matchingpassword, name, company, reCaptchaToken)
            .then(() => {
                resolve();
            })
            .catch(reject);
        });
    }, []);
  
    const value = {
        user,
        login,
        logout,
        register,
        reloadUser
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
  
export const useAuth = () => {
    return useContext(AuthContext);
};
  