import { useState } from 'react';
import { Box, IconButton, Typography, Modal } from '@mui/material';
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { ThemeProvider } from '@mui/material/styles';

import { theme } from '../Theme';


/** Default stylized box */
export const ModalBox = styled(Box)`
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
`;

/** Stylized modal with info
 * 
 * @param {object} props
 * @param {string} [props.title]
 * @param {string} [props.info]
 * @returns
*/
export default function InfoModal(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <ThemeProvider theme={theme}>
            <IconButton sx={{mt: 0, py: 0}} onClick={handleOpen}><InfoIcon sx={{fontSize: "1.3rem"}}/></IconButton>
            <Modal open={open} onClose={handleClose}>
                <Box sx={{    
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: props.width ? props.width : 400,
                    overflowY: 'scroll',
                    height:'80%',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4
                }}>
                    <Typography sx={{float: "right"}} id="modal-modal-close" variant="h6" component="h3">
                        <IconButton onClick={handleClose}><CloseIcon/></IconButton>
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {props.title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        { props.info }                        
                    </Typography>
                </Box>
            </Modal>
        </ThemeProvider>
    );
}
