import { AuthorizationError } from "./error";
import { baseUrl } from "./settings";


function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
  
    for (const element of ca) {
        let c = element;
    
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
    
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
  
    return "";
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

/** Generates basic authentication token
 * 
 * @param {string} userLogin 
 * @param {string} password
 * @returns {string}
 */
 export function basicAuthToken(userLogin, password) {
    return btoa(`${userLogin}:${password}`);
}

/** Creates basic authentication headers using token
 * 
 * @param {string} token 
 * @returns {Headers}
 */
 export function basicAuthHeaders(token) {
    let headers = new Headers();
    headers.append("Authorization", `Basic ${token}`);

    return headers;
}

/** Creates default headers with bearer token
 * 
 * @param {string} bearerToken 
 * @returns {Headers}
 */
export function authHeaders(bearerToken) {
    let xsrf = getCookie("XSRF-TOKEN");
    
    if (!xsrf) {
        throw new Error("Cannot get xsrf token");
    }
  
    let headers = new Headers();
    headers.append("X-XSRF-TOKEN", xsrf);
    setCookie("XSRF-TOKEN", xsrf, 1);

    headers.append("Authorization", `Bearer ${bearerToken}`);

    return headers;
}

/** Sends request to backend to logout
 * 
 * @param {string} bearerToken 
 * @returns {Promise}
 */
export function logout(bearerToken) {
    return new Promise(async (resolve, reject) => {
        let xsrf = getCookie("XSRF-TOKEN");
    
        if (!xsrf) {
            return reject(new Error("Cannot get xsrf token"));
        }
    
        let headers = authHeaders(bearerToken);

        fetch(`${baseUrl}/logout`, {
            method: "POST",
            credentials: "include",
            headers,
            redirect: "follow",
        })
        .then(async (response) => {
            await response.json();
            resolve();
        })
        .catch(reject);
    });
}

/** Sends request to backend to login
 * 
 * @param {string} basicToken 
 * @returns {Promise}
 */
export function login(basicToken, reCaptchaToken) {
    return new Promise(async (resolve, reject) => {
        let xsrf = getCookie("XSRF-TOKEN");
    
        if (!xsrf) {
            const requestOptions = {
                method: "POST",
                headers: new Headers(),
                redirect: "follow",
                credentials: "include",
            };
            await fetch(`${baseUrl}/csrf`, requestOptions).catch(() => {
                //ignore warnings, only want cookie
            });      
            xsrf = getCookie("XSRF-TOKEN");
        }
    
        if (!xsrf) {
            return reject(new Error("Cannot get xsrf token"));
        }
    
        let headers = basicAuthHeaders(basicToken);
        headers.append("X-XSRF-TOKEN", xsrf);
        headers.append("g-recaptcha-token", reCaptchaToken);
        setCookie("XSRF-TOKEN", xsrf, 0.8);
        fetch(`${baseUrl}/login`, {
            method: "POST",
            credentials: "include",
            headers,
            redirect: "follow",
        })
        .then(async (response) => {
            if (response.headers?.get("error")) {
                return reject(new AuthorizationError(response.headers?.get("error")));
            }

            if (!response.headers?.get("Authorization")) {                
                return reject(new AuthorizationError("No authorization"));
            }

            const authorizationHeader = response?.headers?.get("Authorization");
            const token = authorizationHeader?.replace(/(^Bearer)/gi, "").trim();
            const user = await getUser(token);
            
            if (user.error) {
                return reject(new AuthorizationError(response.error));
            } else {
                return resolve({ ...user, bearerToken: token });
            }
        })
        .catch(reject);
    });
}

/** Sends request to backend to get user information
 * 
 * @param {string} bearerToken 
 * @returns {Promise}
 */
export function getUser(bearerToken) {
    return new Promise(async (resolve, reject) => {
        let xsrf = getCookie("XSRF-TOKEN");
    
        if (!xsrf) {
            const requestOptions = {
                method: "POST",
                headers: new Headers(),
                redirect: "follow",
                credentials: "include",
            };
            
            await fetch(`${baseUrl}/csrf`, requestOptions).catch(() => {});
            xsrf = getCookie("XSRF-TOKEN");
        }

        if (!xsrf) {
            return reject(new Error("Cannot get xsrf token"));
        }
    
        let headers = authHeaders(bearerToken);

        fetch(`${baseUrl}/user`, {
            method: "GET",
            credentials: "include",
            headers,
            redirect: "follow",
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.error) {
                reject(new AuthorizationError(data.error));
            } else {
                resolve(data);
            }
        })
        .catch(reject);
    });
}

/** Sends request to backend to register user
 * 
 * @param {string} firstname 
 * @param {string} lastname 
 * @param {string} password
 * @param {string} matchingPassword 
 * @param {string} email
 * @returns {Promise}
 */
export function register(firstname, lastname, password, matchingPassword, email, reCaptchaToken) {
    let xsrf = getCookie("XSRF-TOKEN");
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-XSRF-TOKEN", xsrf);
    headers.append("g-recaptcha-token", reCaptchaToken);

    return new Promise(async (resolve, reject) => {
        fetch(`${baseUrl}/user`, {
            method: "POST",
            headers,
            redirect: "follow",
            body: JSON.stringify({                
                "firstname": firstname,
                "lastname": lastname,
                "password": password,
                "matchingPassword": matchingPassword,
                "email": email
            })
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.error) {
                reject(new AuthorizationError(data.error));
            } else {
                resolve(data);
            }
        })
        .catch(reject);
    });
}


/** Sends request to backend to check registration token
 * 
 * @param {string} token
 * @returns {Promise}
 */
 export function checkRegistration(token) {
    return new Promise(async (resolve, reject) => {
        fetch(`${baseUrl}/user/confirm?token=${token}`, {
            method: "GET",
            redirect: "follow",
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.error) {
                reject(new AuthorizationError(data.error));
            } else {
                resolve(data);
            }
        })
        .catch(reject);
    });
}

