import React, { useState, useEffect } from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';

import './app.css';
import { PublicLayout } from "./pages/PublicLayout";
import { ProtectedLayout } from "./pages/ProtectedLayout";
import { HomePage, UserPage, LoadDataPage, OverviewPage } from './pages';
import { StoreProvider } from './store/useStore';
import { getSettings } from './models/useGetData';


/** Main entry point for the application handling the routing */
export default function App() {
    const [params] = useSearchParams();
    const [registrationEnabled, setRegistrationEnabled] = useState(false);

    useEffect(() => {
        getSettings().then((data) => setRegistrationEnabled(data.value));
    }, []);

    return (
        <StoreProvider>
            <Routes>
                <Route element={<PublicLayout />}>
                    <Route path="/" element={<HomePage/>} />
                    <Route path="/user/login" element={<UserPage/>} />
                    { registrationEnabled ? <Route path="/user/register" element={<UserPage register/>} /> : ""}
                    { registrationEnabled ? <Route path="/user/confirm" element={<UserPage confirm={params.get("token")}/>} /> : ""}
                </Route>
    
                <Route path="/data" element={<ProtectedLayout />}>
                    <Route path="load" element={<LoadDataPage/>} />
                    <Route path="overview" element={<OverviewPage/>}/>
                </Route>
            </Routes>
        </StoreProvider>
    );
}