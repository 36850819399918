import { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { LoginForm, RegistrationForm, RegistrationConfirmation } from '../components/Forms';
import useGetData from '../models/useGetData';


/** UserPage shows a login or registration form based on its props.
 * @param {object} props
 * @param {boolean} props.register
*/
const UserPage = (props) => {
    let page = <LoginForm/>;
    let [key, setKey] = useState('');
    const { getKey } = useGetData();

    if (props.register) page = <RegistrationForm/>;
    if (props.confirm) page = <RegistrationConfirmation token={props.confirm}/>;

    useEffect(() => {
        getKey().then((captchakey) => {
            setKey(atob(captchakey.captchaKey));
        });
    }, []);
    
    if (key === '') return;

    return (
        <GoogleReCaptchaProvider reCaptchaKey={key}>
            { page }
        </GoogleReCaptchaProvider>
    );
}

export default UserPage;