import MuiTextField from "@mui/material/TextField";
import { ThemeProvider } from '@mui/material/styles';

import { theme } from "../Theme";


/** Default stylized textfield
 *
 * @param {object} props
 * @param {string} props.label
 * @returns
 */
const TextField = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <MuiTextField variant="filled" {...props} />
        </ThemeProvider>
    );
};

export default TextField;
