import styled from "styled-components";

const FieldLegend = styled.legend`
    ${props => props.width ? "width: " + props.width + ";" : ";"}
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    ${props => props.fontSize ? "font-size: " + props.fontSize + ";" : "1.1rem;"}
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-top: 1rem;
    margin-bottom: 1rem;
    word-break: break-word;
`;

export default FieldLegend;
