import { Navigate, useOutlet, Link, useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';

import { redirectUrls } from "../models/settings";
import { Page, Panel, Box } from '../components/Surfaces';
import { MenuButton } from "../components/Button";
import { useAuth } from "../context/useAuth";


/** ProtectedLayout sets the structure for a protected page, including a menu bar, and loads data into the store */
export const ProtectedLayout = () => {
    const { user, logout } = useAuth();
    const outlet = useOutlet();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout().finally(() => navigate(redirectUrls.userLogin));
    }

    if (!user) {
        return <Navigate to="/" />;
    }

    return (
        <Page>
            <Box>
                <MenuButton component={Link} to={redirectUrls.dataUpload} sx={{ marginRight: "10px"}}>Load data</MenuButton>
                <MenuButton component={Link} to={redirectUrls.overview} sx={{ marginRight: "100px"}}>Dashboard</MenuButton>
                <MenuButton endIcon={<LogoutIcon/>} onClick={handleLogout}>Logout</MenuButton>
            </Box>
            <Panel height="85vh">
                {outlet}
            </Panel>
        </Page>
    );
};
