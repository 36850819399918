import { useCallback } from "react";

import { baseUrl } from "./settings";
import { authHeaders } from "./auth";
import { useAuth } from "../context/useAuth";
import { AuthorizationError, FetchError } from "./error";


/** Backend requests to post data */

/** Request to save an image file that was uploaded in the image upload form
 * 
 * @param {string} bearerToken
 * @param {file} file
 * @returns {Promise}
 */
function uploadFile(bearerToken, file, path) {
    const headers = authHeaders(bearerToken);
    const url = `${baseUrl}/file/${path}`;
    
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            credentials: "include",
            headers,
            redirect: "follow",
            body: file
        }).then((response) => {            
            if (response?.status === 401) {
                reject(new AuthorizationError(response.error));
            } else if (response.status === 200) {
                if (response.headers.get("content-type") === "application/json") {
                    return response.json();
                } else {
                    return [];
                }
            } else {
                return response.json();
            }
        })
        .then((response) => {
            if (response?.error) {
                reject(new FetchError(response.error));
            } else if (response.hasOwnProperty("_embedded")) {
                resolve(response._embedded);
            }

            resolve(response);
        })
        .catch(reject);
    });
}

/** Different predefined data uploads */
const usePostData = () => {
    const { user } = useAuth();
    
    const uploadImageFile = useCallback((file) => {
        let formData = new FormData();
        formData.append('file', file[0]);
        
        return uploadFile(user.bearerToken, formData, 'image');
    }, [user.bearerToken]);

    const uploadWeightFile = useCallback((file) => {
        let formData = new FormData();
        formData.append('file', file[0]);
        
        return uploadFile(user.bearerToken, formData, 'weight');
    }, [user.bearerToken]);

    return { uploadImageFile, uploadWeightFile };
}

export default usePostData;