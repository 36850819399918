import { createTheme } from '@mui/material/styles';


/** Theme settings for MUI elements */
export const theme = createTheme({
    palette: {
        primary: {
            main: '#8dc63f',
            dark: '#3B6122'
        },
        secondary: {
            main: '#eeeeee',
            dark: '#777777'
        }
    }
});
