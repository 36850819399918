import { useState } from 'react';

import { Tabs } from '../components/Surfaces';
import { ImageFileForm, WeightFileForm } from '../components/Fields';
import { redirectUrls } from '../models/settings';


/** LoadFieldsPage shows a form which redirects to the overview page when fields are uploaded successfully */
function LoadDataPage() {
    return (
        <Tabs 
            width={500}
            labels={["Load image file", "Load weight file"]} 
            orientation="vertical"
        >
            <ImageFileForm redirect={redirectUrls.overview}/>
            <WeightFileForm redirect={redirectUrls.overview}/>
        </Tabs>
    );
}

export default LoadDataPage;