import { useCallback } from "react";

import { baseUrl } from "./settings";
import { authHeaders } from "./auth";
import { useAuth } from "../context/useAuth";
import { AuthorizationError, FetchError } from "./error";

/** Backend requests to retrieve data */

/** Sends request to backend to get basic settings
 * 
 * @returns {Promise}
 */
 export function getSettings() {
    return new Promise(async (resolve, reject) => {
        fetch(`${baseUrl}/user/registration`, {
            method: "GET",
            redirect: "follow",
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.error) {
                reject(new FetchError(data.error));
            } else {
                resolve(data);
            }
        })
        .catch(reject);
    });
}


/** General request to fetch data from a given endpoint
 * 
 * @param {string} endpoint
 * @param {string} bearerToken
 * @returns {Promise}
 */
export function fetchData(endpoint, bearerToken) {
    return new Promise(async (resolve, reject) => {
        const headers = authHeaders(bearerToken);
        const url = `${baseUrl}/${endpoint}`;
        
        fetch(url, {
            method: "GET",
            credentials: "include",
            headers,
            redirect: "follow",
        })
        .then((response) => {
            if (response?.status === 200) {
                return response.json();
            } else if (response?.status === 401) {
                reject(new AuthorizationError(response.error));
            } else if (response?.error) {
                reject(new FetchError(response.error));
            } else {
                console.log(response);
                reject(new FetchError("Unknown error while fetching data."));
            }
        })
        .then((data) => resolve(data))
        .catch(reject);
    });
}

/** Different predefined data requests */
const useGetData = () => {
    const { user } = useAuth();

    const getKey = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            fetch(`${baseUrl}/captchakey`, {
                method: "GET",
                credentials: "include",
                headers: new Headers(),
                redirect: "follow",
            })
            .then((response) => {
                if (response?.status === 200) {
                    return response.json();
                } else if (response?.error) {
                    reject(new FetchError(response.error));
                } else {
                    console.log(response);
                    reject(new FetchError("Unknown error while fetching data."));
                }
            })
            .then((data) => resolve(data))
            .catch(reject);
        });
    }, []);

    const getUserData = useCallback(() => {
        if (user && user.bearerToken) {
            return fetchData('user', user.bearerToken);
        } else {
            return Promise.reject(new Error("User not logged in"));
        }
    }, [user]);

    return { getKey, getUserData };
};

export default useGetData;