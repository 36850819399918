import styled from "styled-components";
import img_page from '../../images/cocoa.jpg'
import img_header from '../../images/cocoa.jpg'


/** Default stylized page and header */

const Page = styled.section`
    padding: 16px 16px 0px 16px;
    background-color: #EEEEEE;
    background-image: url(${img_page});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100% - 16px);
`;

export const PageHeader = styled.h1`
    color: white;
    background-color: #003300;
    font-size: 2rem;
    background-image: url(${img_header});
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 5px;
    padding-left: 4rem;
    padding-top: 0.8rem;
    padding-bottom: 1.3rem;
`;

export default Page;
