import { useOutlet } from "react-router-dom";

import { Page } from "../components/Surfaces";


/** PublicLayout sets the structure for a public page */
export const PublicLayout = () => {
    const outlet = useOutlet();

    return (
        <Page>
            {outlet}
        </Page>
    );
};
