import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";

import { redirectUrls } from "../../models/settings";
import { Panel, PanelHeader } from "../Surfaces";
import { DropZone } from "../Input";
import { LoadButton } from "../Button";
import usePostData from "../../models/usePostData";
import { ConfirmationDialog } from "../Dialogs";

/** FieldsForm constructs a fields form to drag and drop shape weight files with field definitions or to connect to eHerkenning to retrieve field definitions */
const WeightFileForm = () => {
    const [ files, setFiles ] = useState([]);
    const [ weightfileProcess, setWeightfileProcess ] = useState(-1);
    const { uploadWeightFile } = usePostData();
    const [ confirmationState, setConfirmationState ] = useState(false);
    const navigate = useNavigate();
    const [alertState, setAlertState] = useState({ message: null, severity: "error" });
    
    const showError = (msg) => setAlertState({ message: msg, severity: "error" });

    const handleSubmit = (event) => {
        if (files.length === 0) {
            setWeightfileProcess(0);
            setConfirmationState(true);
        } else {
            setWeightfileProcess(1);
        }
    }

    function proceedOnConfirmation(confirmation) {
        if (confirmation) {
            setWeightfileProcess(1);
        } else {
            setWeightfileProcess(-1);
        }

        setConfirmationState(false);
    }

    useEffect(() => {
        if (files.length === 0) return;

        showError(null);
    }, [ files ]);

    useEffect(() => {
        if (weightfileProcess === 1) {
            setWeightfileProcess(2);
            uploadWeightFile(files)
            .then((response) => { 
                setFiles([]);
                setWeightfileProcess(-1);
                navigate(redirectUrls.overview);
            })
            .catch((err) => {
                setFiles([]);
                setWeightfileProcess(-1);
                showError("The weight file cannot be uploaded.");
                console.error(err);
            });
        }
    }, [ weightfileProcess ]);

    return (
        <Panel centered="true" width="1000px">
            <PanelHeader>Load Weight File</PanelHeader>
            {alertState?.message && (<Alert severity={alertState.severity}>{alertState.message}</Alert>)}
            <Grid container sx={{ mt: 3 }}>
                <Grid item xs={12}>
                    <DropZone files={files} setFiles={setFiles} title="Drag and drop a weight file (.pth)"/>
                    <LoadButton id="weightfiles" loading={weightfileProcess === 2} onClick={handleSubmit} style={{marginTop: 10}}>
                        Load weight file
                    </LoadButton>
                </Grid>            
            </Grid>

            <ConfirmationDialog 
                open={confirmationState} 
                handleClose={proceedOnConfirmation} 
                title="No weight file" 
                question={"Please select an weight file."}
            ></ConfirmationDialog>
        </Panel>
    );
}

export default WeightFileForm;