/** 
 * The list of indicators in the store can be set with SET_INDICATORS
 * The farm id in the store can be set with SET_FARM 
 * The list of field ids in the store can be set with SET_FIELDS
*/
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_IMAGE':
            return { ...state, image: action.payload };
        case 'SET_RESULT':
            return { ...state, result: action.payload };
        default:
            throw new Error(`Unknown action ${action.type}`);
    }
};

export default reducer;
