export class FetchError extends Error {
    constructor(message) {
        super(message);
        this.name = "FetchError";
    }
}

export class AuthorizationError extends FetchError {
    constructor(message) {
        super(message);
        this.name = "AuthorizationError";
    }
}
