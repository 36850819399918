/** Utility functions */

/* Get a list of years from field data */
export const getYears = (fields) => {
    let years = fields.map((field) => {return field.year});

    return years.sort();
}

/* Get a list of field IDs for a given year from field data */
export const getFieldIds = (fieldsPerYear, year) => {
    let fieldIds = [];
    fieldsPerYear.filter(fields => fields.year === year)
    .map((element) => element.fields)
    .forEach((fields) => fieldIds.push(...fields.map((field) => field.id)));

    return fieldIds.sort();
}

/* Get the internal field id for the given agrodatacube id */
export const getFieldId = (fieldsPerYear, year, agrodatacubeId) => {
    let fieldId = fieldsPerYear.filter(fields => fields.year === year)
    .map((element) => element.fields)[0]
    .filter(field => field.agrodatacubeId === agrodatacubeId);

    if (fieldId.length > 0) {
        return fieldId[0].id;
    } else {
        return null;
    }
}

export const getNameWithUnit = (name, unit) => {
    let result = name;
    
    if (unit && (name.indexOf(unit) < 0))  {
        if (unit === 'hectare') {
            result += ' (ha)';
        } else if (unit === 'percentage') {
            result += ' (%)';
        } else if (unit !== 'dimensionless') {
            result += ' (' + unit + ')';
        }
    }

    return result;
}

/* Format an indicator value, rounding it to 2 digits in case of a float value */
export function formatIndicator(value, unit = '', decimals = 2) {
    if (unit === 'dimensionless') {
        return value;
    } else if (typeof value === 'number') {
        let number = value;
        
        if (!(value === +value && value === (value|0))) {
            number = number.toFixed(decimals);
        }

        let formattedUnit = "";
        
        if (unit === "percentage") {
            formattedUnit = " %";
        } else {
            formattedUnit = " " + unit;
        }

        return number + formattedUnit;
    } else {
        if (value === 'true') {
            return 'Ja';
        } else if (value === 'false') {
            return 'Nee';
        }
        
        return value;
    }
}
  
/* Check if a text occurs as a consecutive sequence in the list of characters  */
function checkSequences(listOfCharacters, text) {
    for (let i  = 0; i < listOfCharacters.length - 5; i++) {
        if (text.toLowerCase().includes(listOfCharacters.substring(i, i + 5))) {
            return false;
        }
    }

    return true;
}

/* Check password on:
- length between 8 and 32 characters
- at least one upper-case character
- at least one lower-case character
- at least one digit character
- at least one symbol (special character)
- some illegal sequences that will fail when >= 5 chars long alphabetical is of the form 'abcde', numerical is '34567', qwery is 'asdfg'*/
export function checkPassword(password) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "1234567890";
    const qwerty = "qwertyuiopasdfghjklzxcvbnm";
    
    if ((password.length < 8) || (password.length > 32)) {
        return  "Your password must be between 8 and 32 characters long";
    } else if (password.toUpperCase() === password) {
        return "Your password must contain at least one lowercase letter";
    } else if (password.toLowerCase() === password) {
        return "Your password must contain at least one uppercase letter";
    } else if (password.replaceAll(" ", "") !== password) {
        return "Your password cannot contain spaces";
    } else if (!/\d/.test(password)) {
        return "Your password must contain at least one number";
    } else if (!specialChars.test(password)) {
        return "Your password must contain at least one special character";
    } else if (!checkSequences(alphabet, password)) {
        return "Your password cannot contain more than 4 letters in alphabetical order";
    } else if (!checkSequences(numbers, password)) {
        return "Your password cannot contain more than 4 numbers in sequence";
    } else if (!checkSequences(qwerty, password)) {
        return "Your password cannot contain more than 4 letters from the QWERTY keyboard in sequence";
    }

    return null;
}

/** Add an integer number of days to date */
 export function addDays(date, days) {
    return new Date(date.getTime() + days*24*60*60*1000);
}

export function getEndPointFromSubPath(subPath) {
    return subPath.substring(subPath.indexOf("-") + 1).replace("-", "");
}

export function getNameFromSubPath(subPath) {
    let name = subPath.substring(subPath.indexOf("-") + 1).replaceAll("-", " ");
    name = name.charAt(0).toUpperCase() + name.slice(1);
    
    return name;
}

export function sortIndicators(indicators) {
    indicators.sort((a, b) => {
        let a_codegroup = a.code.substring(0, a.code.indexOf("_"));
        let b_codegroup = b.code.substring(0, b.code.indexOf("_"));
        
        if (a_codegroup === b_codegroup) {
            if (a.id < b.id) {
                return -1;
            } else {
                return a.id > b.id ? 1 : 0;
            }
        } else {
            return (a_codegroup < b_codegroup) ? -1 : 1;
        }
    });
}
