import styled from "styled-components";
import { Box as MuiBox } from "@mui/material";


/** Default stylized box */
const Box = styled(MuiBox)`
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: white;
    display: flex;
    justify-content: flex-end;
`;

export default Box;
