import { useState, useEffect, useCallback } from "react";
import Alert from "@mui/material/Alert";
import { Navigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { useAuth } from "../../context/useAuth";
import { Panel, PanelHeader } from "../Surfaces";
import { TextField, FieldGroup } from "../Input";
import { Button } from "../Button";
import { redirectUrls } from "../../models/settings";
import { getSettings } from "../../models/useGetData";


/** LoginForm constructs a form to login to the website and redirects to the farm overview after login */
const LoginForm = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [registrationEnabled, setRegistrationEnabled] = useState(false);
    const [formState, setFormState] = useState({ email: "", password: "" });
    const [busy, setBusy] = useState(false);
    const [alertState, setAlertState] = useState({ message: null, severity: "error" });
    const { user, login } = useAuth();
  
    useEffect(() => {
        getSettings().then((data) => setRegistrationEnabled(data.value));        
    }, [ setRegistrationEnabled ]);

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) return;
  
        const captchaToken = await executeRecaptcha('login');
        return captchaToken;
    }, [executeRecaptcha]);
  
    if (user && user.bearerToken) {
        return <Navigate to={redirectUrls.dataUpload} replace/>;
    }

    const showError = (msg) => setAlertState({ message: msg, severity: "error" });

    const getChangeHandler = (parameter) => (event) => {
        const value = event.target.value;
        setFormState((prev) => ({ ...prev, [parameter]: value }));
    };

    const handleLogin = () => {
        if (!formState.email) {
            showError("Enter your email");
        } else if (!formState.password) {
            showError("Enter your password");
        } else {
            setBusy(true);
            handleReCaptchaVerify().then((token) => {
                login(formState.email, formState.password, token)
                .catch((err) => {
                    if (err.message.includes("Bad credentials")) {
                        showError("Username and/or password is incorrect");
                    } else if (err.message.includes("disabled")) {
                        showError("Not confirmed yet your registration. Click on the link in the email you received.");
                    } else {
                        showError("Cannot log in");
                    }

                    console.error(err);
                })
                .finally(() => {
                    setBusy(false);   
                });
            })
            .catch((err) => {
                showError("Issue with Google Captcha. Try logging in again.");
                setBusy(false);   
            });
        }
    }
        
    return (
        <Panel centered="true" width="500px">
            <PanelHeader>Login</PanelHeader>
            <FieldGroup flexDirection="column">
                <TextField
                    label="Email"
                    value={formState.email}
                    onChange={getChangeHandler("email")}
                    color="primary"
                />
                <TextField
                    label="Password"
                    value={formState.password}
                    onChange={getChangeHandler("password")}
                    type="password"
                />
            </FieldGroup>

            {alertState?.message && (<Alert severity={alertState.severity}>{alertState.message}</Alert>)}

            <div style={{ marginTop: 16 }}>
                <Button color="primary" disabled={busy} onClick={handleLogin}>
                    log in
                </Button>
                { 
                    registrationEnabled ? 
                    <a style={{ marginLeft: 16 }} href={'#' + redirectUrls.userRegistration}>Register</a>
                    : ""
                }
            </div>
        </Panel>
    );
};

export default LoginForm;
