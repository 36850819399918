import styled from "styled-components";
import Paper from "@mui/material/Paper";

/** Default stylized panel and header */

/** Default stylized paper to act as panel
 * 
 * @param {object} props
 * @param {string} [props.width]
 * @param {string} [props.height]
 * @param {string} [props.centered]
 * @returns
*/
const Panel = styled(Paper)`
    ${props => props.width ? "max-width: " + props.width + ";" : ";"}
    ${props => props.height ? "min-height: " + props.height + ";" : ";"}
    padding: 16px;
    margin: ${props => props.centered ? "16px auto 16px auto" : "0px"};
    width: 90%;
    width: calc(100% - 32px);
    height: 90%;
    height: calc(100% - 32px);
`;

export const PanelHeader = styled.h2``;

export default Panel;
