import { createContext, useReducer, useContext } from 'react';
import initialState from './initialState';
import reducer from './reducer';


const StoreContext = createContext();

export const StoreProvider = (props) => {
    const [store, dispatch] = useReducer(reducer, initialState);
    return <StoreContext.Provider value={{ store, dispatch }} {...props} />;
};

/**
 * @typedef {Object} TStoreContext
 * @property {*} store
 * @property {function} dispatch
 *
 * @returns {TStoreContext}
 */
const useStore = () => useContext(StoreContext);

export default useStore;
