import { useState } from "react";
import styled from "styled-components";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


/** Groups inputs in a row by default */
const StyledFieldSet = styled.fieldset`
    display: flex;
    ${props => props.flexDirection ? "flex-direction: " + props.flexDirection + ";" : "row;"}
    gap: 16px;
    padding: 10px;
`;
const StyledLegend = styled.legend``;

const FieldSet = ({ label, children, ...props }) => {
    return (
        <StyledFieldSet {...props} >
            {label && <StyledLegend>{label}</StyledLegend>}
            {children}
        </StyledFieldSet>
    );
};

export default FieldSet;

export const CollapsibleFieldSet = ({ label, children, collapse, ...props }) => {
    const [collapsed, setCollapsed] = useState(collapse);

    return (
        <StyledFieldSet 
            {...props} 
            onClick={(event) => { 
                if ((event.target.tagName !== 'INPUT') && (event.target.tagName !== 'SPAN')) setCollapsed(!collapsed)
            }}
        >
            {label && <StyledLegend>
                {collapsed ? <KeyboardArrowDownIcon sx={{float: 'left', marginRight: 1}}/> : <KeyboardArrowUpIcon sx={{float: 'left', marginRight: 1}}/>}
                {label}
            </StyledLegend>}            
            {collapsed ? '' : children}
        </StyledFieldSet>
    );
};