import FileUpload from "react-material-file-upload";
import { ThemeProvider } from '@mui/material/styles';

import { theme } from '../Theme';


/** Default stylized dropzone to handle file uploads
 *
 * @param {object} props
 * @param {string} props.files
 * @param {function} props.setFiles
 * @param {boolean} props.title
 * @returns
 */
 export default function DropZone(props) {
    return (
        <ThemeProvider theme={theme}>
            <FileUpload buttonText={"Select"} value={props.files} onChange={props.setFiles} title={props.title}/>
        </ThemeProvider>
    );
}
